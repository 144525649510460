<template>
    <div class="c-menu-page">
        <slot></slot>
    </div>
</template>

<script>
import pageMixin from '../../mixins/page'
export default {
  name: 'XpMenuPage',
  mixins: [pageMixin]
}
</script>
